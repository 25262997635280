import {useEffect, useMemo, useState, useCallback, useRef} from 'react'
import {useLocation, useParams} from 'react-router-dom'

/** @deprecated use useSearchParams instead */
export function useLocationParams<
    PathParams extends string = string,
    SearchParams extends string = string,
>() {
    const path = useParams<Record<PathParams, string>>()
    const location = useLocation()
    const searchParams = useMemo<Record<SearchParams, string | string[] | undefined>>(
        () => getSearchParams(location.search),
        [location],
    )
    const getParams = useCallback(
        () => ({
            path,
            search: searchParams,
        }),
        [path, searchParams],
    )

    const [params, setParams] = useState(getParams)

    useEffect(
        () => {
            setParams(getParams())
        },
        [path, location, getParams],
    )

    return params
}

// TODO: используется в одном единственном месте - подумать нужна ли она вообще
export function getUrl(url: string | URL, searchParamMap = {}) {
    url = new URL(url)
    populateSearchParams(searchParamMap, url.searchParams)
    return `${url}`
}

export function getSearchString(searchParamMap = {}) {
    return `?${populateSearchParams(searchParamMap)}`
}

export function mergeSearchString(searchParamMap = {}) {
    const searchParams = new URLSearchParams(location.search)

    for (const key of Object.keys(searchParamMap))
        searchParams.delete(key)

    return `?${populateSearchParams(searchParamMap, searchParams)}`
}

function populateSearchParams(searchParamMap: Record<string, unknown>, searchParams = new URLSearchParams) {
    return Object
        .entries(searchParamMap)
        .reduce(
            (searchParams, [name, value]) => {
                if (value !== undefined)
                    for (const singleValue of [value].flat())
                        searchParams.append(name, `${singleValue}`)

                return searchParams
            },
            searchParams,
        )
}

export function getSearchParams(searchString: string) {
    return [...new URLSearchParams(searchString).entries()]
        .reduce<Record<string, string | string[]>>(
            (paramMap, [key, value]) => {
                let existingParam = paramMap[key]

                if (existingParam) {
                    if (!Array.isArray(existingParam))
                        existingParam = paramMap[key] = [existingParam]

                    existingParam.push(value)
                } else
                    paramMap[key] = value

                return paramMap
            },
            {},
        )
}

type ParamDescription = string | never[]

type ParamDescriptions = Record<string, ParamDescription>

type ParamResult<T extends ParamDescription> = T extends never[]
    ? string[]
    : string

export type ParamResults<T extends ParamDescriptions> = {
    [Key in keyof T]: ParamResult<T[Key]>
}

export function useSearchParams<T extends ParamDescriptions>(paramDescriptions: T) {
    const location = useLocation()
    const paramDescriptionsRef = useRef(paramDescriptions)

    return useMemo(
        () => getTypedSearchParams(location.search, paramDescriptionsRef.current),
        [location.search],
    )
}

export function compareSearchParams<T extends Record<string, string | string[]>>(oldParams: T, newParams: T) {
    const different: string[] = []
    const keys = [...new Set([...Object.keys(oldParams), ...Object.keys(newParams)])]

    keys.forEach(key => {
        if (oldParams[key] != newParams[key]) {
            if (!newParams[key] && !oldParams[key])
                return

            if (
                !oldParams[key] && newParams[key] ||
                !newParams[key] && oldParams[key]
            ) {
                different.push(key)
                return
            }

            const isArray = Array.isArray(oldParams[key])

            if (isArray) {
                if (
                    oldParams[key].length != newParams[key].length ||
                    (oldParams[key] as string[]).some(value => !(newParams[key] as string[]).includes(value))
                ) {
                    different.push(key)
                    return
                }
            } else {
                different.push(key)
                return
            }
        }
    })

    return different
}

// пример использования useSearchParams
// допустим, адрес страницы /projects?page=5&x=6&x=7
/* eslint-disable */
function Component() {
    const {page, x, y, z} = useSearchParams({
        page: '1',
        x: [],
        y: [],
        z: 'abc',
    })

    console.log(page) // '5'
    console.log(x) // ['6', '7']
    console.log(y) // []
    console.log(z) // 'abc'
}
/* eslint-enable */

export function getTypedSearchParams<T extends ParamDescriptions>(
    searchString: string,
    paramDescriptions: T,
) {
    const searchParams = new URLSearchParams(searchString)

    const typedParams = Object.fromEntries(
        Object.entries(paramDescriptions)
            .map(([name, defaultValue]) => {
                const value = Array.isArray(defaultValue)
                    ? searchParams.getAll(name)
                    : searchParams.get(name) || defaultValue

                return [name, value]
            }),
    ) as ParamResults<T>

    return {
        ...getSearchParams(searchString),
        ...typedParams,
    }
}
