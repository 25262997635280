const {protocol, host} = window.location

let settings

function isIpLike(host: string) {
    return /^(\d{1,3}\.){3}\d{1,3}$/.test(host)
}

const DEV_CONFIG = {
    HOST: window.location.origin,
    BACKEND: 'https://app.v15.touchpoint-analytics.xyz/api',
    WS_BACKEND: 'wss://app.v15.touchpoint-analytics.xyz/api/events',
    AUTH_BACKEND: 'https://auth.v15.touchpoint-analytics.xyz',
    MEDIA_URL_PREFIX: 'https://app.v15.touchpoint-analytics.xyz',

    // BACKEND: 'https://app.v20.touchpoint-analytics.ru/api',
    // WS_BACKEND: 'wss://app.v20.touchpoint-analytics.ru/api/events',
    // AUTH_BACKEND: 'https://auth.v20.touchpoint-analytics.ru',
    // MEDIA_URL_PREFIX: 'https://app.v20.touchpoint-analytics.ru',

    // BACKEND: 'https://dev.touchpoint-analytics.xyz/api',
    // WS_BACKEND: 'wss://dev.touchpoint-analytics.xyz/api/events',
    // AUTH_BACKEND: 'https://dev.touchpoint-analytics.xyz/auth',
    // MEDIA_URL_PREFIX: 'https://dev.touchpoint-analytics.xyz',

    // BACKEND: 'https://uzb.dss-lab.local/backend',
    // WS_BACKEND: 'wss://uzb.dss-lab.local/backend/events',
    // AUTH_BACKEND: 'https://uzb.dss-lab.local/',
    // MEDIA_URL_PREFIX: 'https://uzb.dss-lab.local/',
}

if (process.env.MODE == 'production') {
    const authBackend = isIpLike(window.location.hostname) || process.env.AUTH == 'strict'
        ? `${host}/auth`
        : host.replace(/^[^.]+/, 'auth')

    settings = {
        HOST: `${protocol}//${host}`,
        BACKEND: `${protocol}//${host}/api`,
        WS_BACKEND: `${protocol == 'https:' ? 'wss' : 'ws'}://${host}/api/events`,
        AUTH_BACKEND: `${protocol}//${authBackend}`,
        MEDIA_URL_PREFIX: '',
        USE_HASH: false,
    }
} else
    settings = {
        ...DEV_CONFIG,
    }

export const {
    HOST,
    BACKEND,
    WS_BACKEND,
    AUTH_BACKEND,
    MEDIA_URL_PREFIX,
} = settings
