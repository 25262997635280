import {getAuth} from './user'

const languageLoaders: Record<string, () => Promise<{default: Record<string, unknown>}>> = {
    ru: () => import('../localization/ru.yaml'),
    en: () => import('../localization/en.yaml'),
}

let locale: Record<string, unknown> = {}

function getTranslation(key: string) {
    const translation = locale[key] as string | undefined

    if (translation === undefined && process.env.MODE != 'production')
        console.warn('trl key not found:', key)

    return translation
}

/**
 * Используется для перевода фраз
 *
 * @example Простой перевод по ключу:
 *     trl`Имя пользователя`
 * или:
 *     trl('Имя пользователя')
 *
 * @example Перевод с подстановкой значения во фразу:
 *     trl`Удалить файл ${file.name}?`
 * или:
 *     trl('Удалить файл %0?', file.name)
 *
 * @example Перевод с динамическим формированием ключа:
 *     trl(`_плеер.скорость.${speed}`)
 */
export function trl(parts: TemplateStringsArray | string, ...params: unknown[]) {
    const key = getTranslationKey(parts)
    const translation = getTranslation(key) || key

    return translation.replace(/%(\d)/g, (_, number: string) => params[+number] as string)
}

/**
 * Используется для перевода фраз с подстановкой количества, фраза согласуется с количеством
 *
 * @example
 *     trlCount`Удалить ${documents.length} документов?`
 * или:
 *     trlCount('Удалить %0 документов?', documents.length)
 */
export function trlCount(parts: TemplateStringsArray | string, ...params: [number, ...unknown[]]) {
    const key = getTranslationKey(parts)
    const [count] = params
    let actualKey = key

    const leastSignificantDigit = count % 10
    const tens = (count / 10 | 0) % 10

    if (tens != 1)
        if (leastSignificantDigit == 1)
            actualKey += '.1'
        else if (leastSignificantDigit >= 2 && leastSignificantDigit < 5)
            actualKey += '.2'

    const translation = getTranslation(actualKey) || key

    return translation.replace(/%(\d)/g, (_, number: string) => params[+number] as string)
}

function isTemplateStringsArray(parts: TemplateStringsArray | string): parts is TemplateStringsArray {
    return !!(parts as TemplateStringsArray).raw
}

export function getTranslationKey(parts: TemplateStringsArray | string) {
    return isTemplateStringsArray(parts)
        ? parts.reduce((key, part, index) => {
            const paramPlaceHolder = index < parts.length - 1
                ? `%${index}`
                : ''

            return key + part + paramPlaceHolder
        }, '')
        : parts
}

export function trlObject(key: string) {
    return locale[key]
}

export function loadLocale() {
    return getAuth()
        .then(({profile}) => profile.personal_settings?.lang || '')
        .catch(() => '')
        .then(loadLocaleForLanguage)
}

export function loadLocaleForLanguage(language: string) {
    return Promise.resolve(language in languageLoaders ? languageLoaders[language] : Object.values(languageLoaders)[0])
        .then(loader => loader())
        .then(localeModule => localeModule.default)
        .then(profileLocale => locale = profileLocale)
}
